import { Montserrat } from "@next/font/google";
const laila = Montserrat({ weight: '300' });

export const Title: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  return (
    <h1 className={["text-4xl tracking-tight", laila.className].join(" ")}>
      {children}
    </h1>
  );
};
