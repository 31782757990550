import routesService from "services/routes";

interface UpsertSessionData {
  id: string;
  name?: string;
  email?: string;
  sessionReferralId?: string;
  country?: string;
  invtationCode?: string;
}

interface UpsertSessionErrorResponse {
  type: 'error';
  error: string;
}

interface UpsertSessionSuccessResponse {
  type: 'success';
  id: string;
  invitationSuccess: boolean | undefined;
}

type UpsertSessionResponse = UpsertSessionErrorResponse | UpsertSessionSuccessResponse;

export async function upsertPhotoSession(data: UpsertSessionData): Promise<UpsertSessionResponse> {
  let errorMessage = "An unexpected error ocurred. Please try again later.";
  try {
    const response = await fetch(routesService.api().photoSession(), {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        id: data.id,
        email: data.email,
        name: data.name,
        country: data.country,
        sessionReferralId: data.sessionReferralId,
        invitationCode: data.invtationCode,
      }),
      })
    if (response.ok) {
      const responseData = await response.json();
      return {
        type: 'success',
        id: responseData.id,
        invitationSuccess: responseData.invitationSuccess
      };
    } 

    if (response.status == 400) {
      errorMessage = "Invalid email or name."
    }
  } catch (e) {
    console.error(e)
  }

  return { error: errorMessage, type: 'error' }
}