import cameraFlowService, { PlaneStep } from "./camera-flow-service";

function setPhotoSessionId(photoSessionId: string) {
  return localStorage.setItem('photoSessionId', photoSessionId);
}

function getPhotoSessionId() {
  return localStorage.getItem('photoSessionId');
}

function setStep(planeStep: PlaneStep | 'success') {
  return localStorage.setItem('photoSessionStep', planeStep);
}

function getStep() {
  const maybePlaneStep = localStorage.getItem('photoSessionStep');
  return cameraFlowService.isPlaneStep(maybePlaneStep) ? maybePlaneStep : null;
}

function setSessionSuccessAt(successAt: Date) {
  return localStorage.setItem('photoSuccessAt', successAt.toISOString());
}

function getSessionSuccessAt() {
  return localStorage.getItem('photoSuccessAt');
}

const api = {
  setPhotoSessionId,
  getPhotoSessionId,
  setStep,
  getStep,
  setSessionSuccessAt,
  getSessionSuccessAt
}

export default api;
