import { LoadingIcon } from "./LoadingIcon";

export interface ButtonProps
extends React.DetailedHTMLProps<
  React.ButtonHTMLAttributes<HTMLButtonElement>,
  HTMLButtonElement
> {
  children: React.ReactNode;
  loading?: boolean;
}

export const Button: React.FC<ButtonProps> = ({ children, ...props }) => {
  return (
    <button 
      className={`bg-white-600 text-primary font-normal border-primary border-2 py-2 px-4 rounded-md ${props.loading && 'flex justify-center'}`}
      {...props}
    >
      { props.loading ? <LoadingIcon /> : children }
    </button>
  )
}