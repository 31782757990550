import routesService from "services/routes";

interface SendSuccessMailData {
  photoSessionId: string;
}

interface SendSuccessMailErrorResponse {
  type: 'error';
  error: string;
}

interface SendSuccessMailSuccessResponse {
  type: 'success';
  id: string;
}

type SendSuccessMailResponse = SendSuccessMailErrorResponse | SendSuccessMailSuccessResponse;

export async function sendSuccessMail(data: SendSuccessMailData): Promise<SendSuccessMailResponse> {
  let errorMessage = "An unexpected error ocurred. Please try again later.";
  try {
    const response = await fetch(routesService.api().sendSuccessMail(), {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ photoSessionId: data.photoSessionId }),
      })
    if (response.ok) {
      const responseData = await response.json();
      return {
        type: 'success',
        id: responseData.id
      };
    } 

    if (response.status == 400) {
      errorMessage = "Invalid email or name."
    }
  } catch (e) {
    console.error(e)
  }

  return { error: errorMessage, type: 'error' }
}