import { PresignedPost } from 'aws-sdk/clients/s3';
import routesService from '../routes';

export interface ImageMetadata {
  xPosition: string;
  yPosition: string;
  startedAt?: Date;
  endedAt?: Date;
}

interface GetPreSignedPostData {
  fileName: string;
  fileType: string;
  metadata: ImageMetadata;
}

interface GetPreSignedPostResponse {
  url: string;
  fields: PresignedPost.Fields;
}

export async function getPreSignedPost(data: GetPreSignedPostData): Promise<GetPreSignedPostResponse> {
  const res = await fetch(
    routesService.api().uploadFile(), {
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        fileName: data.fileName,
        fileType: data.fileType,
        metadata: data.metadata
      })
    }
  );

  return await res.json()
}