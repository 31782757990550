import Image from 'next/image';
import { useState, useRef, useCallback } from 'react';
import Webcam from 'react-webcam';
import Lottie from 'react-lottie-player'
import * as animationData from '../public/success-animation.json'
import { LoadingIcon } from './LoadingIcon';

const CAPTURE_IN_MS = 2000;
const RESET_IN_MS = 200;

const videoConstraints = {
  width: 500,
  height: { min: 500 },
  facingMode: { exact: "environment" }
};

export const LoadingScreen: React.FC = () => {
  return (
    <div className="absolute z-20 flex items-center flex-col
      space-y-2 justify-center w-full h-full bg-gray-200"
    >
      <LoadingIcon />
      <p> Camera loading </p>
    </div>
  )
}

export const AnimatedBorder: React.FC<{
  animate: boolean;
  reset: boolean;
  position: 'top' | 'bottom' | 'left' | 'right';
}> = ({ animate, position, reset }) => {
  return (
    <div className={
      `absolute bg-green-600
      ${ position === 'top' || position === 'bottom' ? ' h-4' : ' w-4 '}
      ${ position === 'bottom' && ' bottom-0'}
      ${ position === 'right' && ' right-0'}
      ${ animate && (position === 'top' || position === 'bottom') && ' w-full'}
      ${ (!animate && (position === 'top' || position === 'bottom')) && ' w-0'}
      ${ (animate && (position === 'right' || position === 'left')) && ' h-full'}
      ${ (!animate && (position === 'right' || position === 'left')) &&' h-0'}
      ${ animate && (position === 'bottom' || position === 'right') && 'delay-[800ms]'}
      ${ !animate && (position === 'top' || position === 'left') && 'delay-[800ms]'}
      ${ !reset && 'transition-all duration-1000'}
      ${ reset && 'delay-[0ms]'}
      `
    }
    />
  )
}

export const Camera: React.FC<{
  onScreenshotSuccess: (screenshot: string) => void;
}> = ({ onScreenshotSuccess }) => {
  const [ captureSuccess, setCaptureSuccess ] = useState(false);
  const [ longPress, setLongPress ] = useState(false);
  const [ imageSrc, setImageSrc ] = useState('');
  const captureTimerRef = useRef<NodeJS.Timeout>();
  const resetTimerRef = useRef<NodeJS.Timeout>();
  const [media, cameraRecording] = useState(false);
  const webcamRef = useRef<Webcam>(null);

  const reset = useCallback(
    () => {
      setCaptureSuccess(true);
      setImageSrc('');
    },
    []
  );

  const capture = useCallback(
    () => {
      const newImageSrc = webcamRef.current?.getScreenshot();
      if (newImageSrc) {
        setImageSrc(newImageSrc);
      }
    },
    [webcamRef]
  );

  function handleOnMouseDownOrTouchStart() {
    setCaptureSuccess(false);
    setLongPress(true);
    
    captureTimerRef.current = setTimeout(() => {
      capture();
    }, CAPTURE_IN_MS);
  }
  
  function handleOnMouseUpOrTouchEnd() {
    setLongPress(false);
    clearTimeout(captureTimerRef.current);
  }

  function animatePressTransition() {
    return (longPress || imageSrc !== '') && !captureSuccess;
  }

  return (
    <div className='relative select-none touch-none w-[500px] h-[500px]'
      onMouseDown={handleOnMouseDownOrTouchStart}
      onMouseUp={handleOnMouseUpOrTouchEnd}
      onTouchStart={handleOnMouseDownOrTouchStart}
      onTouchEnd={handleOnMouseUpOrTouchEnd}
    >
      {media !== true && <LoadingScreen />}
      <AnimatedBorder animate={animatePressTransition()} reset={captureSuccess} position='top' />
      <AnimatedBorder animate={animatePressTransition()} reset={captureSuccess} position='bottom' />
      <AnimatedBorder animate={animatePressTransition()} reset={captureSuccess} position='right' />
      <AnimatedBorder animate={animatePressTransition()} reset={captureSuccess} position='left' />
      { imageSrc && 
        <div className='absolute top-[50%] left-[50%] -translate-x-2/4 -translate-y-2/4'>
          <Lottie 
            style={{ width: 100, height: 100 }}
            animationData={animationData}
            play
            loop={false}
            onComplete={() => {
              if (imageSrc) {
                onScreenshotSuccess(imageSrc);
                resetTimerRef.current = setTimeout(() => {
                  reset();
                }, RESET_IN_MS);
              }
            }}
          />
        </div>
      }
      { imageSrc ?
      <Image src={imageSrc} width={500} height={500} alt='example' className='touch-none'/> :
      <Webcam 
        controls={false}
        height={500}
        width={500}
        ref={webcamRef}
        videoConstraints={videoConstraints}
        screenshotFormat="image/png"
        className='border border-green'
        onUserMedia={() => {
          cameraRecording(true)
        }}
      />
      }
    </div>
  )
}