import { getPreSignedPost, ImageMetadata } from "./get-pre-signed-post";
import { postImages, ImageData } from "./image";
import { sendSuccessMail } from "./send-success-mail";
import { upsertPhotoSession } from "./photo-session";

const clientApi = {
  getPreSignedPost,
  upsertPhotoSession,
  postImages,
  sendSuccessMail
}
  
export type { ImageData, ImageMetadata };
export default clientApi;