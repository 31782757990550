const PLANE_STEPS = ["0", "1", "2", "3", "4", "5", "6", "7", "8", "9", "10", "11", "12", "13", "final"] as const;

export type PlaneStep = typeof PLANE_STEPS[number]

interface PlaneData {
  fileName: string;
  instructionsImage: {
    src: string;
    alt: string;
  },
  imageMetadata: {
    xPosition: string;
    yPosition: string;
  }
}

const PLANES_DATA: (Record<PlaneStep, PlaneData>) = {
    "0": {
      fileName: 'plane-1',
      instructionsImage: {
        src: '/planes/plane-1.png',
        alt: 'plane-1'
      },
      imageMetadata: {
        xPosition: '0',
        yPosition: '0',
      }
    },
    "1": {
      fileName: 'plane-2',
      instructionsImage: {
        src: '/planes/plane-2.png',
        alt: 'plane-2'
      },
      imageMetadata: {
        xPosition: '30',
        yPosition: '0',
      }
    },
    "2": {
      fileName: 'plane-3',
      instructionsImage: {
        src: '/planes/plane-3.png',
        alt: 'plane-3'
      },
      imageMetadata: {
        xPosition: '30',
        yPosition: '30',
      }
    },
    "3": {
      fileName: 'plane-4',
      instructionsImage: {
        src: '/planes/plane-4.png',
        alt: 'plane-4'
      },
      imageMetadata: {
        xPosition: '0',
        yPosition: '30',
      }
    },
    "4": {
      fileName: 'plane-5',
      instructionsImage: {
        src: '/planes/plane-5.png',
        alt: 'plane-5'
      },
      imageMetadata: {
        xPosition: '30',
        yPosition: '30',
      }
    },
    "5": {
      fileName: 'plane-6',
      instructionsImage: {
        src: '/planes/plane-6.png',
        alt: 'plane-6'
      },
      imageMetadata: {
        xPosition: '-30',
        yPosition: '0',
      }
    },
    "6": {
      fileName: 'plane-7',
      instructionsImage: {
        src: '/planes/plane-7.png',
        alt: 'plane-7'
      },
      imageMetadata: {
        xPosition: '-30',
        yPosition: '-30',
      }
    },
    "7": {
      fileName: 'plane-8',
      instructionsImage: {
        src: '/planes/plane-8.png',
        alt: 'plane-8'
      },
      imageMetadata: {
        xPosition: '0',
        yPosition: '-30',
      }
    },
    "8": {
      fileName: 'plane-9',
      instructionsImage: {
        src: '/planes/plane-8.png',
        alt: 'plane-8'
      },
      imageMetadata: {
        xPosition: '0',
        yPosition: '-30',
      }
    },
    "9": {
      fileName: 'plane-10',
      instructionsImage: {
        src: '/planes/plane-8.png',
        alt: 'plane-8'
      },
      imageMetadata: {
        xPosition: '0',
        yPosition: '-30',
      }
    },
    "10": {
      fileName: 'plane-11',
      instructionsImage: {
        src: '/planes/plane-8.png',
        alt: 'plane-8'
      },
      imageMetadata: {
        xPosition: '0',
        yPosition: '-30',
      }
    },
    "11": {
      fileName: 'plane-12',
      instructionsImage: {
        src: '/planes/plane-8.png',
        alt: 'plane-8'
      },
      imageMetadata: {
        xPosition: '0',
        yPosition: '-30',
      }
    },
    "12": {
      fileName: 'plane-13',
      instructionsImage: {
        src: '/planes/plane-8.png',
        alt: 'plane-8'
      },
      imageMetadata: {
        xPosition: '0',
        yPosition: '-30',
      }
    },
    "13": {
      fileName: 'plane-14',
      instructionsImage: {
        src: '/planes/plane-8.png',
        alt: 'plane-8'
      },
      imageMetadata: {
        xPosition: '0',
        yPosition: '-30',
      }
    },
    "final": {
      fileName: 'plane-15',
      instructionsImage: {
        src: '/planes/plane-9.png',
        alt: 'plane-9'
      },
      imageMetadata: {
        xPosition: '30',
        yPosition: '30',
      }
    },
}

function isPlaneStep(maybePlaneStep: any): maybePlaneStep is PlaneStep {
  return PLANE_STEPS.includes(maybePlaneStep);
}

function getTotalPlanesCounter() {
  return PLANE_STEPS.length;
}

function getImageDataForPlane(plane: PlaneStep) {
  return PLANES_DATA[plane];
}

function getPlaneNumber(plane: PlaneStep) {
  switch(plane) {
    case '0':
      return 1;
    case '1':
      return 2
    case '2':
      return 3
    case '3':
      return 4
    case '4':
      return 5;
    case '5':
      return 6;
    case '6':
      return 7;
    case '7':
      return 8
    case '8':
      return 9
    case '9':
      return 10
    case '10':
      return 11
    case '11':
      return 12
    case '12':
      return 13
    case '13':
      return 14
    case 'final':
      return getTotalPlanesCounter();
  }
}

function getNextStep(plane: PlaneStep) {
  switch(plane) {
    case '0':
      return '1';
    case '1':
      return '2';
    case '2':
      return '3';
    case '3':
      return '4';
    case '4':
      return '5';
    case '5':
      return '6';
    case '6':
      return '7';
    case '7':
      return '8';
    case '8':
      return '9';
    case '9':
      return '10';
    case '10':
      return '11';
    case '11':
      return '12';
    case '12':
      return '13';
    case '13':
    case 'final':
      return 'final';
  }
}

const api = {
  getTotalPlanesCounter,
  getImageDataForPlane,
  getNextStep,
  isPlaneStep,
  getPlaneNumber
}

export default api;
