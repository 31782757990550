
const routesService = {
  index: () => '',
  success: () => '/success',
  instructions: () => '/instructions',
  cameraFlow: () => '/camera-flow',
  onConstruction: () => '/on-construction',
  privacyPolicy: () => '/privacy-policy',
  api: () => ({
    image: () => '/api/image',
    photoSession: () => '/api/photo-session',
    sendSuccessMail: () => '/api/send-success-mail',
    uploadFile: () => '/api/get-signed-image-url'
  })
}

export default routesService;