import routesService from "services/routes";

export interface ImageData {
  photoSessionId: string;
  url: string;
  name: string;
  type: string | undefined;
}

type PostImagesData = ImageData[]
interface PostImagesErrorResponse {
  type: 'error';
  error: string;
}

interface PostImagesSuccessResponse {
  type: 'success';
  id: string;
}

type PostImagesResponse = PostImagesErrorResponse | PostImagesSuccessResponse;
  
export async function postImages(data: PostImagesData): Promise<PostImagesResponse> {
  let errorMessage = "An unexpected error ocurred. Please try again later.";
  try {
    const response = await fetch(routesService.api().image(), {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ images: data }),
      })
    if (response.ok) {
      const responseData = await response.json();
      return {
        type: 'success',
        id: responseData.id
      };
    } 

    if (response.status == 400) {
      errorMessage = "Invalid images format."
    }
  } catch (e) {
    console.error(e)
  }

  return { error: errorMessage, type: 'error' }
}